import React, {useRef, useEffect, useState} from "react"

import Header from "./Components/Header/Header.js";
import Education from "./Components/Body/Education.js";
import Experience from "./Components/Body/Experience.js";
import Projects from "./Components/Body/Projects.js";
import Skills from "./Components/Body/Skills.js";

import { data, colors, fontSizes } from "./data/cvDataEnglish.js";
// import { data, colors, fontSizes } from "./data/cvDataGerman.js";

const height = 842;
const width = 595;


function CVContent(){
  let [ sizeBase, setSizeBase ] = useState(fontSizes.SIZEBASE)
  let headerRef = useRef();
  let contentRef = useRef();

  //useEffect( () => {
    //if (headerRef.current){
      //let headerHeight = headerRef.current.offsetHeight;
      //let contentHeight = contentRef.current.offsetHeight;
      //let actuallHeight = headerHeight + contentHeight;
      //if (actuallHeight > height){
        //setSizeBase(sizeBase - 1);
      //} else if (actuallHeight < 0.8 * height) {
        //setSizeBase(sizeBase + 1);
      //}
    //}

  //}, [headerRef.current && headerRef.current.offsetHeight, contentRef.current && contentRef.current.offsetHeight] )

  let styles = {
    contaierStyle: {
      height: height,
      width: width,
      display: "flex",
      flexDirection: "column",
      fontFamily: "'Roboto', sans-serif",
    },
    bodyStyle: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
    },
    bodyPart: {
      marginLeft: "20px",
      alignSelf: "stretch",
      justifyContent: "space-around",
      alignItems: "space-between",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    contentContainerStyle: {
      //flexGrow : 1,
      flexShrink : 1,
      display: "flex",
      flexDirection: "column",
    },
    headerContainerStyle: {
      display: "flex",
      flexDirection: "column",
    },
  }
  return(
    <div style={styles.contaierStyle}>
      <div id="headerContainer" ref={headerRef} style={styles.headerContainerStyle}>
        <Header fontSizes={fontSizes} data={data} colors={colors} sizeBase={sizeBase} containerHeight={height} containerWidth={width} />
      </div>
        <div style={styles.bodyStyle}>
          <div style={styles.contentContainerStyle} ref={contentRef} >
            <div style={styles.bodyPart}>
              <Skills fontSizes={fontSizes} data={data} colors={colors} sizeBase={sizeBase} />
              <Experience fontSizes={fontSizes} data={data} colors={colors} sizeBase={sizeBase} />
              <Projects fontSizes={fontSizes} data={data} colors={colors} sizeBase={sizeBase} />
              <Education fontSizes={fontSizes} data={data} colors={colors} sizeBase={sizeBase} />
            </div>
          </div>
        </div>
    </div>
  )
}

export default CVContent

import React, {useRef} from "react";

import Icon from "../Icon.js";

function Header({ containerWidth, containerHeight, ...props }){
  let data = props.data;
  let colors = props.colors;
  let fontSizes = props.fontSizes;

  let sizeBase = props.sizeBase || fontSizes.SIZEBASE;
  let styles = {
    header: {
      //height: containerHeight * 0.15,
      backgroundColor: colors.headerColor,
      width: containerWidth - 20 + "px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      paddingTop: "10px",
      paddingRight: "10px",
      paddingLeft: "10px",
      paddingBottom: "10px",
      color: colors.headerContentColor,
    },
    resumeSummaryContainer: {
      display: "flex",
      flexGrow: 1,
      marginVertical: "10px",
      maxWidth: containerWidth * 0.55,
    },
    contactContainer: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",
    },
    contactElementStyle : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    contactTextStyle: {
      marginRight: "0px",
      padding: "0px",
      paddingRight: "-2pt",
      fontSize: sizeBase + fontSizes.contacts + "pt",
      textAlign: "right",
    },
    personContainer: {
      display: "flex",
      flexDirection: "column",
    },
    nameText:{
      fontSize: sizeBase + fontSizes.name + "pt",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    titleText: {
      //fontStyle: "italic",
      fontSize: sizeBase + fontSizes.jobtitle + "pt",
    },
    sumaryText: {
      fontSize: sizeBase + fontSizes.sumaryText + "pt",
      //fontStyle: "italic",
    },
    portraitStyle: {
      height: "100px",
      marginRight: "15px",
      marginLeft: "5px",
    }
  }

  return(
    <div style={styles.header}>
      <img style={ styles.portraitStyle } alt="Me" src={require("../../assets/Me_cut.jpg")} />
      <div style={styles.resumeSummaryContainer}>
        <div style={styles.personContainer}>
          <div style={styles.nameText}> {data.name} </div>
          <div style={styles.titleText}> {data.title} </div>
          <div style={styles.sumaryText}> {data.resumeSumary} </div>
        </div>
      </div>
      <div style={{flexGrow: 1}} />
      <div style={styles.contactContainer}>
        { data.contactsData.map( (element, index) => (
          <div style={styles.contactElementStyle}>
            <div style={{flexGrow: 1}}/>
            <div style={{ ...styles.contactTextStyle, ...( index == data.contactsData.length - 1 ? { marginRight: "-5px" } : {} ) }}>
              {element.text}
            </div>
            <div style={{width: "10px", }}/>
            <Icon size={sizeBase + fontSizes.contactsIcons + "pt"} icon={ element.icon }/>
          </div>
        ))}
      </div>
    </div>
    )
}

export default Header

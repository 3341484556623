import React, {useState} from "react"

function ElementTitle({ color, fontSize, ...props }){
  let styles = {
    titleContainer:{
      display: "flex", 
    },
    titleText: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 8px 0px 4px",
      backgroundColor: color,
      color: "white",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center",
      fontSize: fontSize || "12pt",
    },
    iconStyle: {
      marginRight: "6px",
      paddingTop: "4px",
      ...(props.iconStyle || {}),
    }
  }
  return(
    <div style={styles.titleContainer}>
      <div style={styles.titleText}>
        <div style={styles.iconStyle}>
          {props.icon || null}
        </div>
        {props.title}
      </div>
      <div style={{flexGrow: 1}}/>
    </div>
    )
}

export default ElementTitle

import React, {useState} from "react"

import ElementTitle from "./ElementTitle.js";

import Icon from "../Icon.js";
import { FaChartPie} from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";


function Experience(props){
  let data = props.data;
  let colors = props.colors;
  let fontSizes = props.fontSizes;

  let sizeBase = props.sizeBase || fontSizes.SIZEBASE;
  

  let styles= {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    dataContainer: {
      marginTop: "4px",
      marginBottom: "4px",
      display: "flex",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
    },
    titleText: {
      fontStyle: "italic",
      fontSize: sizeBase + fontSizes.elementTitle + "pt",
      fontWeight: "500",
    },
    placeText: {
      marginTop: "2px",
      fontSize: sizeBase + fontSizes.elementSubTitle + "pt",
      fontWeight: "400",
      color: "#333333",
      fontStyle: "italic",
    },
    dateText: {
      marginLeft: "auto",
      marginRight: "25px",
      fontSize: sizeBase + fontSizes.date + "pt",
      fontWeight: "400",
      color: "#333333",
      whiteSpace: "nowrap",
    },
    bulletStyle: {
      fontSize: sizeBase + fontSizes.bulletPoint + "pt",
      display: "flex",
      alignItems: "flex-start",
      marginLeft: "2px",
      marginBottom: "2px",
    },
    linkContainer:{
      fontSize: sizeBase + fontSizes.links + "pt",
      fontWeight: "bold",
    },
    linkText:{
      borderWidth: "0px",
      color: colors.mainColor,
      textDecoration: "none",
      marginLeft: "2pt",
      fontWeight: "400",
    },
  }

  return(
    <div style={styles.container}>
      <ElementTitle 
        title={data.experience.title}
        color={colors.mainColor}
        fontSize={sizeBase + fontSizes.areaTitle + "pt"}
        icon={ <Icon size={sizeBase + fontSizes.areaTitle + "pt"} icon={ <FaChartPie color={"white"}/> } /> }
        iconStyle={{paddingTop: "4px", marginLeft: "2px"}} 
      />
      { data.experience.elements && data.experience.elements.map( (element) => {
        return (
          <React.Fragment>
            <div style={styles.dataContainer}>
              <div style={styles.textContainer}>
                <div style={styles.titleText}>{element.place}</div>
                <div style={styles.placeText}>{element.title}</div>
                {
                  element.data && element.data.map( bulletPoint => {
                    return (
                      <div style={styles.bulletStyle}>
                        <div style={{ marginRight: "4px" }}>
                          <Icon size={sizeBase + fontSizes.bulletPoint + "pt"} icon={ <AiOutlineRight color={"black"}/>} />
                        </div>
                        { bulletPoint }
                      </div>
                    );
                  })
                }
                {element.links &&
                  <div style={styles.linkContainer}>
                    Links: 
                    {element.links.map( (link, index) => {
                      return (
                        <React.Fragment>
                          <a style={ styles.linkText} target="_blank" href={link.url}>{ link.title }</a>
                          { index < element.links.length -1 ? "," : "" }
                        </React.Fragment>
                      )
                    } )}
                  </div>
                }
              </div>
              <div style={styles.dateText}>{element.date}</div>
            </div>
          </React.Fragment>
        )
      }) }
    </div>
    )
}

export default Experience

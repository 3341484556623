import React from 'react';

import { MdEmail, MdPhone, MdLocationOn, } from "react-icons/md";
import { AiFillGithub } from "react-icons/ai"
import { FaLink } from "react-icons/fa"

export const colors = {
  //mainColor: "#0073e6",
  mainColor: "#3842ff",
  headerColor: "#ffffff",
  headerContentColor: "#000000",
};

//Sizing in pt
export const fontSizes = {
  SIZEBASE: 7,
  name: 7,
  contacts: 0,
  contactsIcons: 2,
  jobtitle: 1,
  sumaryText: 0,
  areaTitle: 4,
  elementTitle: 3,
  elementSubTitle: 1,
  date: 1,
  skillText: 1,
  bulletPoint: 1,
  links: 1,

  
  //name: "14pt",
  //contacts: "7pt",
  //contactsIcons: "9pt",
  //jobtitle: "8pt",
  //sumaryText: "7pt",
  //areaTitle: "11pt",
  //elementTitle: "10pt",
  //elementSubTitle: "8pt",
  //date: "8pt",
  //skillText: "8pt",
  //bulletPoint: "8pt",
  //links: "8pt",
};

export const data = {
  name: "Nils Rohr",
  title: "Full Stack Developer",
  resumeSumary: "Proactive Full Stack Developer. Over one year experience in App Development, including Frontend and Backend. Experienced in motivating, managing and guiding small development teams. Experience in research and development in barely developed but growing fields like WebVR and WebXR.",
  contactsData: [
    {icon : (<MdEmail color={colors.mainColor}/>), text: (<a style={{textDecoration: "none", color: colors.headerContentColor}} href={"mailto:NilsRohr94@gmail.com"}>NilsRohr94@gmail.com</a>)},
    {icon : (<MdPhone color={colors.mainColor}/>), text: "+49 1575 5846278"},
    {icon : (<MdLocationOn color={colors.mainColor}/>), text: "Kaiserslautern, Rheinland-Pfalz, Germany"},
    {icon : (<AiFillGithub color={colors.mainColor}/>), text: (<a target="_blank" style={{textDecoration: "none", color: colors.headerContentColor}} href={"https://github.com/Renji3"}>https://github.com/Renji3</a>)},
    {icon : (<FaLink color={colors.mainColor}/>), text: (<a target="_blank" style={{textDecoration: "none", color: colors.headerContentColor}} href={"https://coding-bits.net"}>https://coding-bits.net</a>)},
  ],

  education: {
    title: "Education",
    elements: [
      {
        title: "Bachelor Angewandte Informatik",
        place: "Technische Universitaet Kaiserslautern",
        date: "October 2019",
        data: [ 
          (<div style={{ display: "flex"}}><div style={{ fontWeight: "bold", marginRight: "5px" }}>Bachelorthesis:</div> Software- and Hardware-Architecture-Design for Enhancing Anki Overdrive’s User Experience with Augmented Reality</div> ),
        ],
      },
    ],
  },
  experience: {
    title: "Experience",
    elements: [
      {
        title: "Assistant in Research",
        place: "Technische Universitaet Kaiserslautern",
        date: "January 2020 - June 2020",
        data: [
          "Assistance in research regarding HCI (Human Computer Interaction) and XR (Mixed Reality, Augmented Reality, Virtual Reality)",
          "Planned, designed and developed a website to upload, convert and display and interact with various 3D models (Frontend & Backend)",
        ],
        links: [{ url: "https://hci-model-uploader.web.app", title: "Website" },],
      },
      {
        title: "Web Developer",
        place: "Zreality",
        date: "July 2020 - Now",
        data: [
          "Successfully created an extensive chat application for an enterprise client",
          "Introducing and implementing ReactJS to a VanillaJS legacy software",
          "Quickly learned and helped team members to learn new frameworks like VueJS, Threejs, Aframe on a professional level"
        ],
        links: [
          {title: "Zreality", url: "https://zreality.com"},
          {title: "SWR", url: "https://virtuell.swr.de"},
          {title: "Think IBM", url: "https://www.zreality.com/2020/09/23/the-virtual-ibm-think-pop-up-ibm-selects-zreality-technology-to-launch-a-hybrid-event-platform-in-3d-and-virtual-reality/?lang=en"}],
      },
    ],
  },
  projects: {
    title: "Projects",
    elements: [
      {
        title: "PeerUp - Cross-Platform Social Media App for finding new friends",
        date: "March 2018 - January 2020",
        data: [
          "Designed, structured, planned and developed the App's frontend (React Native) and backend (Firebase)",
          "Led a small team of developers",
        ],
        links: [
          {title: "Landing Page", url: "https://peerup.eu" },
          {title: "PlayStore", url:"https://play.google.com/store/apps/details?id=com.Peerup.production"}
        ],
      },
      {
        title: "Flow",
        date: "Feburary 2020",
        data: [
          "Developed a prototype of a browser extension that lets you record and replay user interactions on a website with the goal of knowledge transfere and automation.",
          "Used Webpack and React in a browser extension to inject custom UI elements into any website",
        ],
        links: [
          {title: "Git", url:"https://github.com/Renji3/FlowPrototype"}
        ],
      },
      {
        title: "Personal Blog and smaller Projects",
        date: "Ongoing",
        data: [
          "Designed, developed and maintained a personal Blog to document personal projects and frameworks that really got my interest.",
          "Created a Hasura based bootstrap project with enabled auth",
        ],
        links: [
          {title: "Website", url:"https://coding-bits.net"},
          {title: "Hasura Post", url: "https://coding-bits.net/blog/hasura-login-docker"}
        ],
      },
    ],
  },
  skills: {
    title: "Skills",
    subTitle: "Technical Skills",
    elements: [
      {
        area: "Languages",
        skillList: ["Javascript", "Typescript", "HTML", "CSS", "C++", "C#", "Java"],
      },
      {
        area: "Frameworks Frontened",
        skillList: ["React Native", "ReactJs", "Redux", "Vuejs", "Svelte", "ThreeJS", "Babylonjs"],
        color: "#be8aff",
      },
      {
        area: "Tools",
        skillList: [
          "Git", "Expo", "Jira", "Slack", "Google Analytics", "Firebase Analytics", "Sentry",
        ],
        color: "#45bea4"
      },
      {
        area: "Backend",
        skillList: [ "Hasura", "Nodejs", "Express", "Firebase", "PostgressSQL", "Docker", "Digital Ocean", "Nginx"],
        color: "#1d1a27"
      },
    ],
  },
};

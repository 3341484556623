import React, {useState} from "react"

import ElementTitle from "./ElementTitle.js";

import Icon from "../Icon.js";
import { FaCogs } from "react-icons/fa";


function Skills(props){
  let data = props.data;
  let colors = props.colors;
  let fontSizes = props.fontSizes;

  let sizeBase = props.sizeBase || fontSizes.SIZEBASE;

  let styles= {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    skillsContainer: {
      display: "flex",
      marginBottom: "2px",
      alignItems: "center",
      flexWrap: "wrap",
    },
    skillAreaText: {
      fontWeight: "bold",
      fontSize: sizeBase + fontSizes.elementSubTitle + "pt",
      marginRight: "3px",
    },
    skillListContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    skillText: {
      marginRight: "2px",
      fontSize: sizeBase + fontSizes.skillText + "pt",
      fontWeight: "bold",
      backgroundColor: colors.mainColor,
      borderRadius: "999px",
      color: "white",
      padding: "4px",
      paddingLeft: "4px",
      paddingRight: "4px",
      marginTop: "2px",
    },
    skillHeader: {
      fontStyle: "italic",
      fontSize: sizeBase + fontSizes.elementTitle + "pt",
      fontWeight: "bold",
    },
    softSkillContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    softSkill: {
      fontSize: sizeBase + fontSizes.skillText + "pt",
      fontWeight: "bold",
      marginRight: "4px",
      marginBottom: "2px",
      backgroundColor: colors.mainColor,
      borderRadius: "999px",
      color: "white",
      padding: "4px",
      paddingRight: "6px",
    },
  }
  
  return(
    <div style={styles.container}>
      <ElementTitle 
        title={data.skills.title}
        color={colors.mainColor}
        fontSize={sizeBase + fontSizes.areaTitle + "pt"}
        icon={<Icon size={sizeBase + fontSizes.areaTitle + "pt"} icon={<FaCogs color={"white"}/>}/>}
      />
      <div style={{ height: "5px" }} />
      {/* <div style={styles.skillHeader}>{data.skills.subTitle}: </div> */}
      <div style={styles.skillsContainer}>
        { data.skills.elements && data.skills.elements.map( (element) => {
          return (
                //<div style={styles.skillAreaText}>{element.area}:</div>
                //<div style={styles.skillListContainer}>
                  element.skillList.map( (skill, index) => {
                    let backgroundColor = { backgroundColor: element.color || colors.mainColor};
                    return (
                      <React.Fragment>
                        <div style={{...styles.skillText, ...backgroundColor}}>{ skill }</div>
                        { index === element.skillList.length - 1  ? <div style={{width: "12px"}}/> : null }
                      </React.Fragment>
                    )
                  }) 
              //</div>
            //</React.Fragment>
          )
        }) }
      </div>
      { data.softSkills && (
        <React.Fragment>
          <div style={{ height: "5px" }} />
          <div style={styles.skillHeader}>{data.softSkills.title}: </div>
          <div style={styles.softSkillContainer}>
            { data.softSkills.elements && data.softSkills.elements.map( ( softSkill ) => {
              return (
                <div style={styles.softSkill}>
                  {softSkill}
                </div>
              )
            } ) }
          </div>
        </React.Fragment>
      )}
    </div>
    )
}

export default Skills
